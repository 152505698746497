<template>
  <section class="all-documents-view" v-loading="loading">
    <el-row type="flex" justify="center" align="center">
      <el-col :span="22">
        <div class="inner-navbar mb-2">
          <el-row>
            <el-col :lg="12" :md="12" :sm="24" :xs="24">
              <div class="left-block">
                <div class="icon-block">
                  <div class="icon">
                    <img
                      src="@/assets/img/icons/menu/work-flow.svg"
                      alt="icon"
                      class="img-fluid"
                    />
                  </div>
                  <div class="icon-text">
                    <h3 class="table-head-text">
                      {{ "All Custom Dashboards" }}
                    </h3>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col
              style="text-align: right"
              class="top-buttons-wrapper"
              :lg="12"
              :md="12"
              :sm="24"
              :xs="24"
            >
              <div class="right-block">
                <el-input
                  prefix-icon="el-icon-search"
                  size="small"
                  placeholder="Search"
                  v-model="search_string"
                  @input="loadData"
                  class="search_input"
                  :style="getIsMobile ? 'width:130px' : 'width:170px;'"
                ></el-input>

                <el-button
                  v-if="
                    checkPermissionByPermissionsName('createCustomDashboard')
                  "
                  class="create-btn px-2 ml-2 p-05"
                  type="primary"
                  :style="getIsMobile ? 'width:120px' : 'width:160px;'"
                  @click="openDialog"
                >
                  <i class="fa fa-plus-circle"></i>
                  {{ getIsMobile ? "Create" : "Create Dashboard" }}</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          v-loading="loading"
          class="vue-data-table-default"
          element-loading-text="Loading..."
        >
          <data-tables-server
            v-loading="configureLoading"
            :fullscreen="true"
            :data="data"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            element-loading-text="Fetching dashboards..."
          >
            <el-table-column
              label="Name"
              id="name"
              prop="name"
              width="250"
              fixed="left"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Assigned to"
              id="assigned"
              prop="assigned"
              min-width="200"
            >
              <template slot-scope="scope">
                <div
                  style="display: flex"
                  v-if="scope.row.assigned_to && scope.row.assigned_to.length"
                >
                  <div
                    v-for="(role, i) in getRolesByGroup(scope.row.assigned_to)"
                    :key="i + role + 'table'"
                    class="mr-1"
                  >
                    <el-popover trigger="hover">
                      <div style="display: flex; flex-direction: column">
                        <p
                          v-for="user in role.userTypes"
                          :key="'user' + user.id"
                          style="font-weight: bold"
                        >
                          {{ `${user.name}` }}
                        </p>
                      </div>
                      <el-tag slot="reference" effect="dark">
                        {{ role.name }}
                      </el-tag>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="action" min-width="120" label="Actions">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    Actions
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <a @click="onAssign(scope.row)" style="border: none">
                      <el-dropdown-item icon="el-icon-user" command="edit"
                        >Assign to new user</el-dropdown-item
                      >
                    </a>
                    <a
                      v-if="!getIsMobile"
                      @click="onEdit(scope.row)"
                      style="border: none"
                    >
                      <el-dropdown-item
                        icon="el-icon-edit-outline"
                        command="edit"
                        >Edit</el-dropdown-item
                      >
                    </a>
                    <a @click="onDelete(scope.row)" style="border: none">
                      <el-dropdown-item icon="el-icon-delete" command="delete"
                        >Delete</el-dropdown-item
                      >
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column label="Last modified" width="200">
              <template slot-scope="scope" v-if="scope.row.updated_at">
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.updated_at | moment("MM-DD-YYYY") }}
                </p>
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.updated_at | moment("HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Created at" width="200">
              <template slot-scope="scope" v-if="scope.row.created_at">
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.created_at | moment("MM-DD-YYYY") }}
                </p>
                <p class="time-stamp mb-0 mt-0">
                  {{ scope.row.created_at | moment("HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>
          </data-tables-server>
        </div>
      </el-col>
    </el-row>
    <dialog-component
      :title="
        editingDashboardId ? 'Edit Custom Dashboard' : 'Add Custom Dashboard'
      "
      :visible="dialogVisible"
      :width="getIsMobile ? '100%' : '30%'"
      @before-close="resetModal"
      :containerMaxWidth="'500px'"
    >
      <div class="popUp-content mt-1">
        <label>Dashboard Name</label>
        <el-input
          placeholder="Enter Dashboard name"
          v-model="dashboard_name"
          class="mb-3"
        ></el-input>
        <label class="mt-3">Select user type</label>
        <el-select
          class="mb-15"
          v-model="dashboard_role"
          filterable
          default-first-option
          placeholder="Select Role"
          multiple
          collapse-tags
          style="width: 100%"
        >
          <el-option
            v-for="item in getUserTypeList"
            :key="item._id"
            :label="getLabel(item)"
            :value="item._id"
          ></el-option>
        </el-select>
        <el-checkbox v-model="main_dashboard_visible"
          >Show in dashboard</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Cancel</el-button>
        <el-button
          type="primary"
          @click="saveDashboard"
          :disabled="!dashboard_name"
          >Save</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
import {
  fetchCustomDashboardByPagination,
  deleteCustomDashboard,
  updateAssignedToCustomdashboard,
  addSingleCustomDashboardToLocal,
} from "@/repo/customDashboardRepo";
export default {
  data() {
    return {
      loading: false,
      configureLoading: false,
      dialogVisible: false,
      data: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dashboard_name: "",
      dashboard_role: [],
      main_dashboard_visible: true,
      search_string: "",
      editingDashboardId: "",
      disableEdit: true,
    };
  },
  computed: {
    paginationProps() {
      return {
        pageSizes: [5, 10, 20, 50],
      };
    },
    getAllRolesList() {
      if (this.getAllRoles && this.getAllRoles.data) {
        return this.getAllRoles.data;
      }
      return [];
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
    ...mapGetters("roles", ["getAllRoles"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("customDashboard", [
      // "getAllDashboards",
      "getCreateNewDashboard",
      "getDeleteDashboardStatus",
      "getUpdateDashboardStatus",
      "getCreateNewDashboardError",
      "getUpdateDashboardError",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace", "getUserTypeList"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  mixins: [PermissionsHelper],
  async mounted() {
    await Promise.all([
      this.$store.dispatch("auth/getUserTypeList", {
        companyId: this.getActiveWorkspace.company_id,
        isMenu: true,
      }),
      this.getNecessaryInfo(),
    ]);
  },
  methods: {
    shouldDisableEdit() {
      return this.getIsMobile;
    },
    async onDelete(data) {
      if (
        data &&
        data.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != data.created_by &&
        !this.checkPermissionByPermissionsName("deleteCustomDashboard")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for delete dashboard.Please contact owner.",
        });
      } else {
        this.$confirm("Are you sure to delete?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.deleteDashboard(data);
        });
      }
    },
    async deleteDashboard(data) {
      this.loading = true;
      await this.$store.dispatch("customDashboard/deleteDashboard", data._id);
      if (this.getDeleteDashboardStatus) {
        await deleteCustomDashboard(data._id);
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Deleted successfully",
          type: "success",
        });
        await this.getNecessaryInfo();
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Error while deleting dashboard",
          type: "error",
        });
      }
    },
    resetModal() {
      this.dashboard_name = "";
      this.dashboard_role = "";
      this.main_dashboard_visible = true;
      this.editingDashboardId = "";
      this.dialogVisible = false;
    },
    onAssign(data) {
      if (
        data &&
        data.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != data.created_by &&
        !this.checkPermissionByPermissionsName("assignToNewUserCustomDashboard")
      ) {
        this.$notify.error({
          title: "Error",
          message:
            "Permission denied for assign dashboard to new user.Please contact owner.",
        });
      } else {
        this.dashboard_name = data.name;
        this.dashboard_role = data.assigned_to.filter((e) => e);
        this.main_dashboard_visible = data.main_dashboard_visible;
        this.editingDashboardId = data._id;
        this.dialogVisible = true;
      }
    },
    onEdit(data) {
      if (
        data &&
        data.created_by &&
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser._id &&
        this.getAuthenticatedUser._id != data.created_by &&
        !this.checkPermissionByPermissionsName("editCustomDashboard")
      ) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for edit dashboard.Please contact owner.",
        });
      } else {
        this.$router.push({
          name: "custom-dashboard-config",
          params: {
            dashboardId: data._id,
          },
        });
      }
    },
    capitalizeFirstLetter(string) {
      string = string.toLowerCase();
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getRolesByGroup(data) {
      let result = [];
      (this.getUserTypeList || []).forEach((type) => {
        if (data.indexOf(type._id) !== -1 && type.role && type.role._id) {
          let existedRole = result.find((e) => e.roleId == type.role._id);
          if (existedRole) {
            let exitedIndex = result.findIndex(
              (e) => e.roleId == type.role._id
            );
            existedRole.userTypes.push({
              id: type._id,
              name: this.capitalizeFirstLetter(type.name),
            });
            result[exitedIndex] = { ...existedRole };
          } else {
            result.push({
              name: type.role.title,
              roleId: type.role._id,
              userTypes: [
                {
                  id: type._id,
                  name: this.capitalizeFirstLetter(type.name),
                },
              ],
            });
          }
        }
      });
      return result;
    },
    async saveDashboard() {
      this.dashboard_name = this.dashboard_name.trim();
      if (!this.dashboard_name) {
        this.$message.error("Please enter dashboard name");
        return;
      }
      let params = {
        name: this.dashboard_name,
        assigned_to: this.dashboard_role,
        main_dashboard_visible: this.main_dashboard_visible,
      };
      if (this.editingDashboardId) {
        await this.$store.dispatch("customDashboard/updateDashboard", {
          id: this.editingDashboardId,
          params: params,
        });
        if (this.getUpdateDashboardStatus) {
          await updateAssignedToCustomdashboard(
            this.editingDashboardId,
            params
          );
          this.$notify({
            title: "Success",
            message: "Updated successfully",
            type: "success",
          });
          this.resetModal();
          this.getNecessaryInfo();
        } else {
          let msg =
            this.getUpdateDashboardError && this.getUpdateDashboardError.message
              ? this.getUpdateDashboardError.message
              : "Error while editing dashboard";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      } else {
        await this.$store.dispatch(
          "customDashboard/fetchCreateNewDashboard",
          params
        );
        if (this.getCreateNewDashboard && this.getCreateNewDashboard._id) {
          await addSingleCustomDashboardToLocal(this.getCreateNewDashboard);
          this.$router.push({
            name: "custom-dashboard-config",
            params: {
              dashboardId: this.getCreateNewDashboard._id,
            },
          });
        } else {
          let msg =
            this.getCreateNewDashboardError &&
            this.getCreateNewDashboardError.message
              ? this.getCreateNewDashboardError.message
              : "Error while saving data";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      }
    },
    async getNecessaryInfo() {
      this.configureLoading = true;
      let params = {
        page: this.currentPage,
        limit: this.pageSize > 0 ? this.pageSize : 10,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      const [getAllDashboards] = await Promise.all([
        fetchCustomDashboardByPagination(params),
        this.$store.dispatch("auth/getUserTypeList", {
          companyId: this.getActiveWorkspace.company_id,
          isMenu: true,
        })
      ]);
      if (getAllDashboards) {
        this.data = getAllDashboards.data;
        this.total = getAllDashboards.total;
      }
      this.configureLoading = false;
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        await this.getNecessaryInfo();
      }
    },
    async openDialog() {
      this.resetModal();
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.contacts-page {
  &.actions-wrapper {
    justify-content: end;
    .create-btn {
      height: 40px !important;
      margin-left: 10px !important;
    }
    .filter-contact-type {
      margin-left: 10px !important;
    }
    .search-icon-wrapper {
      height: 32px !important;
      padding-top: 6px;
      padding-bottom: 0px;
    }
    @media (max-width: 1166.98px) {
      display: flex;
      flex-direction: column !important;
      .first-line {
        display: flex;
        justify-content: end;
        margin-bottom: 10px !important;
        button {
          margin-top: 0px;
          margin-left: 5px !important;
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
        .search-icon-wrapper {
        }
      }
      .second-line {
        display: flex;
        justify-content: end;
        .filter-contact-type {
          margin-left: 0px !important;
          width: 130px;
        }
        .import-dropdown-btn {
          margin-left: 5px !important;
          button {
            padding: 8px !important;
          }
        }
        .create-btn {
          padding: 9px !important;
          margin-right: 9px !important;
          width: 80px;
        }
      }
    }
  }
}
.top-buttons-wrapper {
  @media (max-width: 991.98px) {
    display: flex;
    margin-top: 20px;
  }
}
.time-stamp {
  font-size: 0.935em !important;
  color: #a1a0a3 !important;
}
.inner-navbar .left-block .icon-block {
  margin-top: 10px;
  @media (max-width:767px){
   margin-top:15px;
  }
}
</style>
